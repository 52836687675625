<template>
  <div :class="[isMobile ? 'mobile-about-responsibility' : '', 'about-responsibility']">
    <item-title v-if="!isMobile" :title="{name: '社会责任', en_1: 'SOCIAL', en_2: 'RESPONSIBILITY'}" />
    <div class="responsibility-content">
      <transition enter-active-class="animated fadeIn">
        <template v-if="isMobile">
          <section v-show="isShow" class="section-2">
            <div class="list">
              <load ref="LoadMore" @loadMore="onScrollBottom">
                <div class="listGroup">
                  <div v-for="item in swiperData" :key="item.id" class="item">
                    <img @click="jumpDetail(item.id)" class="pic" :src="item.pic" alt="">
                    <div @click="jumpDetail(item.id)" class="title underline">{{item.name}}</div>
                    <div @click="jumpDetail(item.id)" class="time">{{item.disploy_at.substring(0,10)}}</div>
                    <div @click="jumpDetail(item.id)" class="desc">{{item.discribe}}</div>
                    <div @click="jumpDetail(item.id)" class="detail"></div>
                  </div>
                </div>
              </load>
            </div>
            <!-- <div class="swiper-box">
              <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
                <swiper-slide v-for="item in swiperData" :key="item.id">
                  <img class="content-img" :src="item.pic" alt="">
                </swiper-slide>
              </swiper>
            </div>
            <div v-if="swiperData.length > 0" class="content">
              <div @click="jumpDetail(swiperData[currentSwiperIndex].id)" class="title underline">{{swiperData[currentSwiperIndex].name || ''}}</div>
              <div class="time">{{swiperData[currentSwiperIndex].disploy_at | dateFormat}}</div>
              <div class="desc">{{swiperData[currentSwiperIndex].discribe}}</div>          
            </div>
            <div @mouseover="swiperStop"
                @mouseleave="swiperStart" class="page-handle">
              <img v-if="currentSwiperIndex == 0" class="prev" src="../../assets/img/aboutus/prev.png" alt="">
              <img @click="swiperHandle('prev')" v-else class="prev" src="../../assets/img/aboutus/prev_active.png" alt="">
              <img v-if="currentSwiperIndex == swiperData.length - 1" class="next" src="../../assets/img/aboutus/next.png" alt="">
              <img @click="swiperHandle('next')" v-else class="next" src="../../assets/img/aboutus/next_active.png" alt="">
            </div> -->
          </section>
        </template>
        <template v-else>
          <section v-show="isShow" class="section-1">
            <div class="swiper-box">
              <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
                <swiper-slide v-for="item in swiperData" :key="item.id">
                  <img class="content-img" :src="item.pic" alt="">
                </swiper-slide>
              </swiper>
            </div>
            <div v-if="swiperData.length > 0" class="content">
              <div @click="jumpDetail(swiperData[currentSwiperIndex].id)" class="title underline">{{swiperData[currentSwiperIndex].name || ''}}</div>
              <div class="time">{{swiperData[currentSwiperIndex].disploy_at.substring(0,10)}}</div>
              <div class="desc">{{swiperData[currentSwiperIndex].discribe}}</div>          
            </div>
            <div @mouseover="swiperStop"
                @mouseleave="swiperStart" class="page-handle">
              <img v-if="currentSwiperIndex == 0" class="prev" src="../../assets/img/aboutus/prev.png" alt="">
              <img @click="swiperHandle('prev')" v-else class="prev" src="../../assets/img/aboutus/prev_active.png" alt="">
              <img v-if="currentSwiperIndex == swiperData.length - 1" class="next" src="../../assets/img/aboutus/next.png" alt="">
              <img @click="swiperHandle('next')" v-else class="next" src="../../assets/img/aboutus/next_active.png" alt="">
            </div>
          </section>
        </template>
      
      </transition>
      <section v-show="isShow" class="section-2">
        <div class="list">
          <load ref="LoadMore" @loadMore="onScrollBottom">
            <div class="listGroup">
              <div v-for="item in listData" :key="item.id" class="item">
                <div @click="jumpDetail(item.id)" class="title underline">{{item.name}}</div>
                <div class="time">{{item.disploy_at.substring(0,10)}}</div>
                <div class="desc">{{item.discribe}}</div>
                <div @click="jumpDetail(item.id)" class="detail"></div>
              </div>
            </div>
          </load>
        </div>
      <pager class="page" :total="total" :page="params2.page" :limit="params2.limit" @current-change="handleCurrentChange"></pager>
      </section>
    </div>
  </div>
</template>

<script>
import ItemTitle from '@/components/aboutUs/itemTitle'
import Load from '@/components/disclosure/load.vue'
import { getNewsAndDutyData } from '@/api/aboutUs'
import Pager from '@/components/common/Pager.vue'
import { isMobile } from '@/helper/utils'
export default {
  components: {
    ItemTitle,
    Load,
    Pager
  },
  data () {
    let _this = this
    return {
      isMobile: isMobile(),
      isShow: false,
      params1: {
        page: 1,
        limit: 25,
        type: 2,
        position: 1
      },
      params2: {
        page: 1,
        limit: 3,
        type: 2,
        position: 0
      },
      total: 0,
      swiperData: [],
      currentSwiperIndex: 0,
      swiperOptions: {
        autoplay: true,
        // loop: true,
        on: {
            slideChange(){
              _this.currentSwiperIndex = _this.$refs.mySwiper.swiper.realIndex
            },
        },
        // Some Swiper option/callback...
      },
      listData: []
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  created () {
    this.getResponsibilityData1()    
  },
  watch:{
    $route(to, from){
      if (from.name != 'Detail' && to.name == 'responsibility') {
        // console.log(1221222112245566, from.name)
        this.params2.page = 1
        this.getResponsibilityData1()
        // console.log(this.getNewsData)
      }      
    }
  },
  mounted () {
    let _this = this
    setTimeout(function () {
      _this.isShow = true
    }, 200)
  },
  filters: {
    dateFormat (val) {
      let d = new Date(val)
      let year = d.getFullYear()
      let month = d.getMonth() + 1
      month = month < 0 ? '0' + month : month
      let day = d.getDate()
      d = day < 0 ? '0' + day : day
      return day + '/' + month + ' .' + year
    }
  },
  methods: {
    onScrollBottom () {
      // console.log(112)
    },
    // 跳转详情页
    jumpDetail (id) {
      this.$emit('loading', true)
      this.$router.push({ path: '/aboutUs/detail?type=responsibility&id=' + id })
    },
    // 轮播控制
    swiperHandle (type) {
      // console.log(this.swiper)
      if (type === 'prev') {
        this.swiper.slidePrev()
      } else {
        this.swiper.slideNext()
      }
    },
    // 停止轮播
    swiperStop () {
      this.swiper.autoplay.pause()
    },
    // 重启轮播
    swiperStart () {
      this.swiper.autoplay.run()
    },
    // 获取社会责任数据(轮播)
    async getResponsibilityData1 () {
      const res = await getNewsAndDutyData(this.params1)
      this.swiperData = res.records
      // console.log(this.swiperData)
      this.getResponsibilityData2()
    },
    // 获取社会责任数据(列表)
    async getResponsibilityData2 () {
      const res = await getNewsAndDutyData(this.params2)
      // console.log(res)
      this.total = res.total
      this.listData = res.records
      this.$emit('loading', false)
    },
    handleCurrentChange(val) {
      this.params2.page = val
      this.$emit('loading', false)
      this.getResponsibilityData2()
    },
  }
}
</script>

<style lang="scss" scoped>
.mobile-about-responsibility.about-responsibility {
   margin-left: 0;
   .page {
     margin-top: 15px!important;
   }
   .responsibility-content {
     padding-top: 0;
     .section-1 {
       display: block;
       .content {
         margin-left: 0;
       }
     }
     .section-2 {
      margin-top: 15px;
      &:first-child {
        margin-top: 0;
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        .listGroup {
          display: flex;
          flex-wrap: wrap;
        }
        .item {
          width: 100%;
          border-top: 2px solid #D0D0D0;
          margin-right: 0;
          margin-bottom: 10px;
          transition: all .3s;
          .pic {
            width: 100%;
          }
          &:hover {
            border-top: 2px solid #fe6917;
            .detail {
              background-image: url(../../assets/img/aboutus/next_active.png);
            }
          }
          cursor: pointer;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .title {
            font-size: .24rem;
            // font-weight: bold;
            // padding-top: .2rem;
            line-height: .6rem;
            display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden;
          }
          .time {
            color: #999999;
            font-size: .15rem;
            margin-top: .1rem;
          }
          .desc {
            font-size: .16rem;
            color: #666666;
            line-height: .3rem;
            height: auto;            
            margin-top: .2rem;
            display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden;
          }
          .detail {
            width: 0.11rem;
            height: 0.16rem;
            margin-top: .5rem;
            background-image: url(../../assets/img/aboutus/next.png);
            background-size: 100% 100%;
          }
        }
      }
    }
   }
}
.underline:hover {
  text-decoration: underline;
  cursor: pointer;
}
.content-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: .3s;
  &:hover {
    transform: scale(1.3);
  }
}
.swiper {
  width: 100%;
  height: 100%;
}
.about-responsibility {
  min-height: 100vh;
  margin-left: 0.6rem;
  .responsibility-content {
    padding-top: .6rem;
    .section-2 {
      margin-top: .6rem;
      .list {
        display: flex;
        flex-wrap: wrap;
        .listGroup {
          display: flex;
          flex-wrap: wrap;
        }
        .item {
          width: 3.2rem;
          border-top: 2px solid #D0D0D0;
          margin-right: .3rem;
          margin-bottom: .3rem;
          transition: all .3s;
          &:hover {
            border-top: 2px solid #fe6917;
            .detail {
              background-image: url(../../assets/img/aboutus/next_active.png);
            }
          }
          cursor: pointer;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .title {
            font-size: .24rem;
            // line-height: .26rem;
            line-height: .3rem;
            height: .6rem;
            // font-weight: bold;
            margin-top: .2rem;
            display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden;
          }
          .time {
            color: #999999;
            font-size: .15rem;
            margin-top: .1rem;
          }
          .desc {
            font-size: .16rem;
            color: #666666;
            line-height: .3rem;
            height: .6rem;            
            margin-top: .2rem;
            display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden;
            text-align: justify;
          }
          .detail {
            width: 0.11rem;
            height: 0.16rem;
            margin-top: .5rem;
            background-image: url(../../assets/img/aboutus/next.png);
            background-size: 100% 100%;
          }
        }
      }
    }
    .section-1 {
      display: flex;
      position: relative;
      .page-handle {
        position: absolute;
        left: 0rem;
        bottom: .3rem;
        margin-top: 1.2rem;
        display: flex;
        align-items: center;
        left: 7.4rem;
        img {
          width: 0.11rem;
          height: 0.16rem;
          margin-right: .4rem;
          cursor: pointer;
        }
      }
      .swiper-box {
        width: 7.1rem;
        height: 4rem;
        background-color: #cccccc;
      }
      .content {
        flex: 1;
        margin-left: .3rem;
        position: relative;
        .title {
          border-top: 2px solid #D0D0D0;
          padding-top: .2rem;
          font-size: .24rem;
          color: #333333;
          // font-weight: bold;
          text-align: justify;
        }
        .time {
          font-size: .15rem;
          color: #999999;
          margin-top: .1rem;
        }
        .desc {
          color: #666666;
          font-size: .16rem;
          line-height: .3rem;
          margin-top: .2rem;
          display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden;
          text-align: justify;
        }
        
      }
    }
  }
}
</style>